import {Injectable} from '@angular/core';
import {environment} from '@Env/environment';
import {Ticket} from './ticket.model';
import {Observable, of, Subject, timer} from 'rxjs';
import {Filter, Status} from '../index';
import {CategorieHelpDesk} from '../categorie/categorie.model';
import {Action} from '../index';
import {IndividuHelpDesk} from '../individu_helpdesk/individu-helpdesk.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Pagination} from '@Common/widgets/general/pagination';
import {TemplateObject} from '@Services/base/interfaces-http';
import {concatMapTo, map, tap} from 'rxjs/operators';

import {CommentTicket} from '../index';
import {SafeHtmlPipe} from '@Common/widgets/pipes';
import {DomSanitizer} from '@angular/platform-browser';
import {Individu} from '@Common/core/services';
import {ListReference} from '../../select-autocomplete/list-reference.model';
import {TicketTypeEnum} from '../../form-fields/extra-fields/extra-fields.enum';

export function polling(period: number, initialDelay = 0) {
  return function<T>(source: Observable<T>) {
    return timer(initialDelay, period).pipe(concatMapTo(source));
  };
}

@Injectable({   providedIn: 'root' })
export class TicketService {
  pagination: Pagination;
  private url = `${environment.baseUrl}/helpdesk/tickets/`;

  /**
   * Hooks création / modification / suppression d'un ticket
   */
  private ticketCreateSubject = new Subject<Ticket>();
  onCreatedTicket$ = this.ticketCreateSubject.asObservable();
  private ticketUpdatedSubject = new Subject<Ticket>();
  onUpdatedTicket$ = this.ticketUpdatedSubject.asObservable();
  private ticketDeletedSubject = new Subject<Ticket>();
  onDeletedTicket$ = this.ticketDeletedSubject.asObservable();

  constructor(private http: HttpClient,
              private sanitized: DomSanitizer) {
    this.pagination = new Pagination();
  }

  /**
   * TODO : à supprimer ou à ne plus appeler, le onCreatedTicket$ est déclenché dans le create()
   * @param ticket
   */
  ticketCreated(ticket: Ticket) {
    this.ticketCreateSubject.next(ticket);
  }

  /**
   * Recherche de tickets sur un N° ou des mots du titre
   * @param keyword
   */
  public searchTickets(keyword: string) {
    const url = `${this.url}search_tickets/`;
    const params = {
      search: keyword
    };
    return this.http
      .get(url, {params})
      .pipe(map(response => this._getPagination(response, 0)));
  }
  /**
   * Export Excel des demandes selon un filtre
   */
  // exportExcel(filter: Filter) {
  //   const url = `${this.url}export_excel/?filter_id=${filter.id}`;
  //   const params = new HttpParams();
  //
  //   return this.http.get(url, { params, responseType: 'blob' }).pipe(map((data: any) => new Blob([data])));
  // }

  // loadRecentTickets(limit = 0, offset = 0, keyword = '', extraQS: Map<string, any> = null): Observable<Pagination> {
  //   const params = this._getParams(limit, offset, keyword, extraQS);
  //   const urlRecent = `${this.url}get_new/`;
  //   return this.http
  //     .get<Ticket[]>(urlRecent, {params})
  //     .pipe(map(response => this._getPagination(response, limit)));
  // }

  list(limit = 0, offset = 0, keyword = '', extraQS: Map<string, any> = null): Observable<Pagination> {
    const params = this._getParams(limit, offset, keyword, extraQS);

    return this.http.get(this.url, {params})
      .pipe(map(response => this._getPagination(response, limit)));

    // return this.http.get<Ticket[]>(this.url, {params});
  }

  private _getByFilterParams(limit = 0, offset = 0, keyword = '',
                             sort: string = null, order: string = null,
                             extraQS: Map<string, any> = new Map<string, any>(),
                             extraDict: Map<string, string[]> = null) {
    return this._getAllParams(limit, offset, keyword, sort, order, extraQS, extraDict);
  }

  exportbyFilter(filterId: number, limit = 0, offset = 0, keyword = '',
                 sort: string = null, order: string = null,
                 extraQS: Map<string, any> = new Map<string, any>(),
                 extraDict: Map<string, string[]> = null) {
    extraQS.set('export', '1');
    const params = this._getByFilterParams(limit, offset, keyword, sort, order, extraQS, extraDict);
    const url = `${this.url}${filterId}/get_by_filter/`;
    // export get_by_filter tourne en async coté backend et retourne un json { task_id: "uuid de la tache" }
    return this.http.get(url, { params });
  }

  /**
   * Obtient les conditions sous forme de texte
   * @param filterId
   */
  getByFilterConditions(filterId: number) {
    const url = `${this.url}${filterId}/get_by_filter_stringify/`;
    return this.http.get<any>(url);
  }

  getByFilter(filterId: number, limit = 0, offset = 0, keyword = '',
              sort: string = null, order: string = null,
              extraQS: Map<string, any> = new Map<string, any>(),
              extraDict: Map<string, string[]> = null) {
    const params = this._getByFilterParams(limit, offset, keyword, sort, order, extraQS, extraDict);
    const url = `${this.url}${filterId}/get_by_filter/`;
    return this.http.get<Ticket[]>(url, {params})
      .pipe(map(response => this._getPagination(response, limit)));
  }

  myTickets(limit = 0, offset = 0, keyword = '', extraQS: Map<string, any> = null): Observable<Pagination> {
    const params = this._getParams(limit, offset, keyword, extraQS);
    const urlMyTickets = `${this.url}my_tickets/`;

    return this.http.get<Ticket[]>(urlMyTickets, {params})
      .pipe(map(response => this._getPagination(response, limit)));
  }

  private _getbyStatusAndCategorieParams(sta: Status, cat: CategorieHelpDesk, keyword = '', limit = 0, offset = 0,
                                         sort: string = null, order: string = null,
                                         extraQS: Map<string, any> = new Map<string, any>(),
                                         extraDict: Map<string, string[]> = null) {
    extraQS = extraQS.set('categorie', cat.id).set('status', sta.id);
    return this._getAllParams(limit, offset, keyword, sort, order, extraQS, extraDict);
  }

  exportbyStatusAndCategorie(sta: Status, cat: CategorieHelpDesk, keyword = '', limit = 0, offset = 0,
                             sort: string = null, order: string = null,
                             extraQS: Map<string, any> = new Map<string, any>(),
                             extraDict: Map<string, string[]> = null) {
    const params = this._getbyStatusAndCategorieParams(sta, cat, keyword, limit, offset, sort, order, extraQS, extraDict);
    // export list tourne en async coté backend et retourne un json { task_id: "uuid de la tache" }
    return this.http.get(this.url, { params });
  }

  byStatusAndCategorie(sta: Status, cat: CategorieHelpDesk, keyword = '', limit = 0, offset = 0,
                       sort: string = null, order: string = null,
                       extraQS: Map<string, any> = new Map<string, any>(),
                       extraDict: Map<string, string[]> = null): Observable<Pagination> {
    const params = this._getbyStatusAndCategorieParams(sta, cat, keyword, limit, offset, sort, order, extraQS, extraDict);
    return this.http.get<Ticket[]>(this.url, {params})
      .pipe(map(response => this._getPagination(response, limit)));
  }

  byCategorie(cat: CategorieHelpDesk, keyword = '', limit = 0, offset = 0,
              sort: string = null, order: string = null,
              extraQS: Map<string, any> = new Map<string, any>(),
              extraDict: Map<string, string[]> = null) {
    extraQS = extraQS.set('categorie', cat.id);

    const params = this._getAllParams(limit, offset, keyword, sort, order, extraQS, extraDict);

    return this.http.get<Ticket[]>(this.url, {params})
      .pipe(map(response => this._getPagination(response, limit)));
  }

  private _getCategoryOnlyActiveParams(cat: CategorieHelpDesk, keyword = '', limit = 0, offset = 0,
                                       sort: string = null, order: string = null,
                                       extraQS: Map<string, any> = new Map<string, any>(),
                                       extraDict: Map<string, string[]> = null) {
    extraQS = extraQS.set('categorie', cat.id);
    return this._getAllParams(limit, offset, keyword, sort, order, extraQS, extraDict);
  }

  exportbyCategorieOnlyActive(cat: CategorieHelpDesk, keyword = '', limit = 0, offset = 0,
                              sort: string = null, order: string = null,
                              extraQS: Map<string, any> = new Map<string, any>(),
                              extraDict: Map<string, string[]> = null) {
    const params = this._getCategoryOnlyActiveParams(cat, keyword, limit, offset, sort, order, extraQS, extraDict);
    // export cat_active_only tourne en async coté backend et retourne un json { task_id: "uuid de la tache" }
    return this.http.get(`${this.url}cat_active_only/`, { params });
  }

  byCategorieOnlyActive(cat: CategorieHelpDesk, keyword = '', limit = 0, offset = 0,
                        sort: string = null, order: string = null,
                        extraQS: Map<string, any> = new Map<string, any>(),
                        extraDict: Map<string, string[]> = null) {
    const params = this._getCategoryOnlyActiveParams(cat, keyword, limit, offset, sort, order, extraQS, extraDict);
    return this.http.get<Ticket[]>(`${this.url}cat_active_only/`, {params})
      .pipe(map(response => this._getPagination(response, limit)));
  }

  private _getCategoriePropagatedParams(cat: CategorieHelpDesk, keyword = '',
                                  limit = 0, offset = 0,
                                  sort: string = null, order: string = null,
                                  extraQS: Map<string, any> = new Map<string, any>(),
                                  extraDict: Map<string, string[]> = null) {
    extraQS = extraQS.set('categorie_src', cat.id);
    return this._getAllParams(limit, offset, keyword, sort, order, extraQS, extraDict);
  }

  exportCategoriePropagated(cat: CategorieHelpDesk, keyword = '',
                            limit = 0, offset = 0,
                            sort: string = null, order: string = null,
                            extraQS: Map<string, any> = new Map<string, any>(),
                            extraDict: Map<string, string[]> = null) {
    const params = this._getCategoriePropagatedParams(cat, keyword, limit, offset, sort, order, extraQS, extraDict);
    // export tree_all tourne en async coté backend et retourne un json { task_id: "uuid de la tache" }
    return this.http.get(`${this.url}tree_all/`, { params });
  }

  byCategoriePropagated(cat: CategorieHelpDesk, keyword = '',
                        limit = 0, offset = 0,
                        sort: string = null, order: string = null,
                        extraQS: Map<string, any> = new Map<string, any>(),
                        extraDict: Map<string, string[]> = null): Observable<Pagination> {
    const params = this._getCategoriePropagatedParams(cat, keyword, limit, offset, sort, order, extraQS, extraDict);
    return this.http.get<Pagination>(`${this.url}tree_all/`, {params})
      .pipe(map(response => this._getPagination(response, limit)));
  }

  private _getSouscriptionsParams(idStatus: Status = null, limit = 0, offset = 0, keyword = '',
                                  sort: string = null, order: string = null,
                                  extraQS: Map<string, any> = new Map<string, any>(),
                                  extraDict: Map<string, string[]> = null) {
    let params: HttpParams;
    if (idStatus) {
      extraQS = extraQS.set('status_id', idStatus.id);
      params = this._getParams(limit, offset, keyword, extraQS);
    } else {
      params = this._getParams(limit, offset, keyword, extraQS);
    }

    if (sort && sort !== '') {
      params = this._getSorting(sort, order, params);
    }

    params = this._getUrlHttpDict(extraDict, params);
    return params;
  }

  exportSouscriptions(idStatus: Status = null, limit = 0, offset = 0, keyword = '',
                      sort: string = null, order: string = null,
                      extraQS: Map<string, any> = new Map<string, any>(),
                      extraDict: Map<string, string[]> = null) {
    const params = this._getSouscriptionsParams(idStatus, limit, offset, keyword, sort, order, extraQS, extraDict);
    // export get_subscriptions tourne en async coté backend et retourne un json { task_id: "uuid de la tache" }
    return this.http.get(`${this.url}get_subscriptions/`, { params });
  }

  getSouscriptions(idStatus: Status = null, limit = 0, offset = 0, keyword = '',
                   sort: string = null, order: string = null,
                   extraQS: Map<string, any> = new Map<string, any>(),
                   extraDict: Map<string, string[]> = null) {
    const params = this._getSouscriptionsParams(idStatus, limit, offset, keyword, sort, order, extraQS, extraDict);
    return this.http.get<Ticket[]>(`${this.url}get_subscriptions/`, {params})
      .pipe(map(response => this._getPagination(response, limit)));
  }

  isExport(extraQS: Map<string, any>) {
    return extraQS !== null && extraQS.has('export');
  }

  private _getMyCategoriesParams(idStatus: Status = null, limit = 0, offset = 0, keyword = '',
                                 sort: string = null, order: string = null,
                                 extraQS: Map<string, any> = new Map<string, any>(),
                                 extraDict: Map<string, string[]> = null) {
    let params: HttpParams;
    if (idStatus) {
      extraQS = extraQS.set('status', idStatus.id);
      params = this._getParams(limit, offset, keyword, extraQS);
    } else {
      params = this._getParams(limit, offset, keyword, extraQS);
    }

    if (sort && sort !== '') {
      params = this._getSorting(sort, order, params);
    }

    params = this._getUrlHttpDict(extraDict, params);

    return params;
  }

  /**
   * Export Excel "Toutes mes catégories"
   * @param idStatus
   * @param limit
   * @param offset
   * @param keyword
   * @param sort
   * @param order
   * @param extraQS
   * @param extraDict
   */
  exportMyCategories(idStatus: Status = null, limit = 0, offset = 0, keyword = '',
                     sort: string = null, order: string = null,
                     extraQS: Map<string, any> = new Map<string, any>(),
                     extraDict: Map<string, string[]> = null) {
    const url = `${this.url}my_categories/`;
    const params = this._getMyCategoriesParams(idStatus, limit, offset, keyword, sort, order, extraQS, extraDict);
    // export my_categories tourne en async coté backend et retourne un json { task_id: "uuid de la tache" }
    return this.http.get(url, { params, responseType: 'blob' });
  }

  /**
   * Les tickets des catégories auxquelles je suis affecté
   */
    getMyCategories(idStatus: Status = null, limit = 0, offset = 0, keyword = '',
      sort: string = null, order: string = null,
      extraQS: Map<string, any> = new Map<string, any>(),
      extraDict: Map<string, string[]> = null) {
    const url = `${this.url}my_categories/`;
    const params = this._getMyCategoriesParams(idStatus, limit, offset, keyword, sort, order, extraQS, extraDict);
    return this.http.get(url, {params}).pipe(map(response => this._getPagination(response, limit)));
  }

  /**
   * Nombre de tickets dans mes catégories ce jour
   */
  getCountMyCategories(extraQS: Map<string, any> = new Map<string, any>()) {
    const params = this._getParams(0, 0, '', extraQS);

    return this.http.get<any>(`${this.url}my_categories_daily_count/`, {params});
  }

  /**
   * Création des HttpParams à partir du Map<string, string[]>
   * @param {Map<string, string[]>} extraQS
   * @param {Map<string, string[]>} urlQS
   * @return {HttpParams}
   * @private
   */
  protected _getUrlHttpDict(extraQS: Map<string, string[]>, urlQS: HttpParams): HttpParams {
    if (extraQS !== null && extraQS.size > 0) {
      extraQS.forEach((v: string[], k) => {
        v.forEach(value => {
          urlQS = urlQS.append(k, value);
        });
      });
    }

    return urlQS;
  }

  /**
   * Enrichissement des HttpParams pour le sorting
   *
   * @param {string} sort : champ de tri
   * @param {string} order : ordre de tri : asc | desc
   * @param {HttpParams} params
   * @return {HttpParams}
   * @private
   */
  protected _getSorting(sort: string, order: string, params: HttpParams) {
    let orderDirection = '';
    let orderField = '';
    if (order) {
      switch (order) {
        case 'asc':
          orderDirection = '';
          break;
        case 'desc':
          orderDirection = '-';
          break;
      }
    }

    if (sort) {
      orderField = `${orderDirection}${sort}`;
      params = params.set('ordering', orderField);
    }

    return params;
  }

  private _getParams(limit: number, offset: number, keyword: string, extraQS: Map<string, any> = null) {
    let params: HttpParams = this.getUrlHttpParams(extraQS);
    if (limit > 0) {
      params = params.set('limit', limit.toString()).set('offset', offset.toString());
    }

    if (keyword && keyword !== '') {
      params = params.set('search', keyword);
    }

    return params;
  }

  private _getPagination(response, limit): Pagination {
    if ('results' in response && limit > 0) {
      const list = response as TemplateObject;
      this.pagination.total = list.count;
      this.pagination.list = list.results;
      this.pagination.totalView = this.pagination.list.length;
    } else {
      const list = response as any[];
      this.pagination.total = list.length;
      this.pagination.list = list;
      this.pagination.totalView = list.length;
    }
    return this.pagination;
  }

  private _getAllParams(limit, offset, keyword, sort, order, extraQS, extraDict) {
    let params = this._getParams(limit, offset, keyword, extraQS);
    if (sort && sort !== '') {
      params = this._getSorting(sort, order, params);
    }
    params = this._getUrlHttpDict(extraDict, params);

    return params;
  }

  get(id: any) {
    return this.http.get<Ticket>(`${this.url}${id}/`);
  }

  update(ticket: Ticket) {
    return this.http
      .put<any>(`${this.url}${ticket.id}/`, ticket)
      .pipe(tap((_ticket: Ticket) => this.ticketUpdatedSubject.next(_ticket)));
  }

  patch(ticket: Ticket): Observable<Ticket> {
    return this.http
      .patch<Ticket>(`${this.url}${ticket.id}/`, ticket)
      .pipe(tap((_ticket: Ticket) => this.ticketUpdatedSubject.next(_ticket)));
  }

  delete(ticket: Ticket) {
    return this.http
      .delete<Ticket>(`${this.url}${ticket.id}/`)
      .pipe(tap(() => this.ticketDeletedSubject.next(ticket)));
  }

  create(ticket: Ticket) {
    return this.http
      .post<Ticket>(this.url, ticket)
      .pipe(tap((_ticket: Ticket) => this.ticketCreateSubject.next(_ticket)));

  }

  fetchHistorique(ticket: Ticket) {
    return this.http.get<Action[]>(`${this.url}${ticket.id}/actions/`);

  }

  deleteSubscription(ticket: Ticket) {
    return this.http.delete<any>(`${this.url}${ticket.id}/subscribe/`);

  }

  getSubscription(id: any) {
    return this.http.get<boolean>(`${this.url}${id}/subscribe/`);
  }

  updateSubscription(ticket: Ticket) {
    return this.http.post<any>(`${this.url}${ticket.id}/subscribe/`, ticket);
  }

  getSubscribers(id: any) {
    return this.http.get<IndividuHelpDesk[]>(`${this.url}${id}/subscribers/`);
  }

  getComments(id: any) {
    return this.http.get<CommentTicket[]>(`${this.url}${id}/comments/`);

  }

  removeSubscriber(ticket: any, uid: any) {
    const opt = {};
    opt['uid'] = uid;
    return this.http.delete<any>(`${this.url}${ticket.id}/subscribers/`, {params: opt});

  }

  addSubscriber(ticket: any, uid: any) {
    return this.http.post<any>(`${this.url}${ticket.id}/subscribers/`, {uid});
  }

  addAffectation(ticket: any, agent= null) {
    let params = {};
    if (agent) {
      params = new HttpParams().set('agent', agent);
    }
    return this.http
      .post<Ticket>(`${this.url}${ticket.id}/affectation/`, {}, {params: params})
      .pipe(tap((_ticket: Ticket) => this.ticketUpdatedSubject.next(_ticket)));
  }

  removeAffectation(ticket: any) {
    return this.http
      .delete<Ticket>(`${this.url}${ticket.id}/affectation/`, {})
      .pipe(tap((_ticket: Ticket) => this.ticketUpdatedSubject.next(_ticket)));
  }

  protected getUrlHttpParams(extraQS: Map<string, string>): HttpParams {
    let urlQS = new HttpParams();

    if (extraQS !== null) {
      extraQS.forEach((v, k) => {
        urlQS = urlQS.set(k, v);
      });
    }

    return urlQS;
  }

  private _getFirstStatus(statuses: Status[]): Status {
    statuses.sort((s1, s2) =>  (s1.ordre > s2.ordre) ? 1 : ((s1.ordre < s2.ordre) ? -1 : 0));
    return statuses[0];
  }

  /**
   * Le statut est-il après le 1er ?
   */
  isAfterFirstStatus(status: Status, statuses: Status[]): boolean {
    const firstStatus = this._getFirstStatus(statuses);
    return status.id !== firstStatus.id;
  }

  /**
   * Est-on sur le 1er statut ?
   * @param status
   * @param statuses
   */
  isFirstStatus(status: Status, statuses: Status[]): boolean {
    const firstStatus = this._getFirstStatus(statuses);
    return status.id === firstStatus.id;
  }

  /**
   * Changement automatique du 1er statut (indice 0, "Nouveau") au suivant (indice 1)
   * @param {Ticket} ticket
   * @param {Status[]} statuses : liste des statuts possibles
   */
  changeFirstStatus(ticket: Ticket, statuses: Status[]): Observable<Ticket> {
    return new Observable(observer => {
      if (ticket.status_object &&
          this.isFirstStatus(ticket.status_object, statuses) &&
          statuses.length > 1) {
        const statusNext = statuses[1];
        if (statusNext) {
          ticket.status = statusNext.id;
          this.patch(ticket).subscribe(_ticket => {
            observer.next(_ticket);
            observer.complete();
          });
        }
      } else {
        observer.next(ticket);
        observer.complete();
      }
    });
  }
  /**
   * Passage en "j'ai lu" / "non lu" les tickets
   * @param obj
   */
  setReadState(obj): Observable<any> {
    if (obj.id) {
      return this.http.delete<any>(`${environment.baseUrl}/helpdesk/ticketslus/${obj.id}/`);
    } else {
      const params = {
        'ticket': obj.ticket,
        'user': obj.user,
      };
      return this.http.post<any>(`${environment.baseUrl}/helpdesk/ticketslus/`, params);
    }
  }

  setReadStateBulk(obj, user, action): Observable<any>{
    if (obj && user && action) {
      const params = {
        'tickets': obj,
        'user': user,
        'action': action,
      };
      return this.http.post<any>(`${environment.baseUrl}/helpdesk/ticketslus/bulk_update/`, params);
    }
  }
  /**
   * Signalétique de la prise en charge des tickets
   * vert : libre
   * orange : les tickets pris par l'agent
   * rouge : non pris
   * @param {Ticket} ticket
   * @param {Individu} connecte
   * @private
   */
  getIconColorAffectation(ticket: Ticket, connecte: Individu) {
    const icon = 'lens';
    let color = '';
    const sp: SafeHtmlPipe = new SafeHtmlPipe(this.sanitized);
    if (ticket.affectation) {
      if (ticket.affectation_object.uid === connecte?.uid) {
        color = 'green';
      } else {
        color = 'orange';
      }
    } else {
      color = 'red';
    }
    return sp.transform(`<i class="material-icons" style="font-size: 18px; color: ${color};">${icon}</i>`);
  }

  /**
   * Les types de demandes
   */
  typesDemand() {
    return of([
      {id: TicketTypeEnum.incident.toString(), label: 'Problème'},
      {id: TicketTypeEnum.service.toString(), label: 'Demande'},
    ]);
  }
}
