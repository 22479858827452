import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Ticket} from '../../common/services';
import {TicketService} from '../../common/services';
import {ActivatedRoute, Router} from '@angular/router';
import {TicketDataSource} from '../../common/services/ticket/ticket.datasource';
import {PaginationBase} from '@Common/widgets/general/pagination';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';

import {debounceTime, distinctUntilChanged, finalize} from 'rxjs/operators';
import {PubSubService} from '../../common/pub-sub.service';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import {User} from '@Services/auth';
import {SubSink} from '@Common/core/utils/subsink';
import {ParameterService} from '../../common/services/parameter/parameter.service';
import {parameters} from '../../common/services/parameter/parameters';

@Component({
  selector: 'app-overview-tickets',
  templateUrl: './overview-tickets.component.html',
  styleUrls: ['./overview-tickets.component.css']
})
export class OverviewTicketsComponent extends PaginationBase implements OnInit, OnDestroy {
  displayedColumns = ['id', 'title', 'status', 'modified', 'link'];
  dataSource: TicketDataSource = new TicketDataSource();
  selectedRowId: number;
  term = new UntypedFormControl();
  subSearch: Subscription;
  searchTick = '';
  private sortBy = '';
  private direction = '';
  private tickets: Ticket[];
  subSink = new SubSink();
  user: User;
  link = '/helpdesk/new-ticket/';
  isInternal = true;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ticketService: TicketService,
              private pubSubSvc: PubSubService,
              private router: Router,
              private route: ActivatedRoute,
              private paramService: ParameterService) {
    super();

    this.subSearch = this.term.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(text => {
        this.searchTick = text;
        this.page = 1;
        this._getTickets(this.page, text);
      });
  }
  ngOnInit() {
    this.MAX_ITEMS = 5;
    this._loadParameter();
    this._getTickets();
    this.user = JSON.parse(localStorage.getItem('user'));
  }
  goToTicket(id: number) {
    this.router.navigate(['helpdesk/ticket', {id: id}], {relativeTo: this.route.parent});
  }
  isSelected(id: number) {
    if (this.selectedRowId === id) {
      return '#F5DEB3';
    }
  }
  getList(page: number = 1) {
    this._getTickets(page);
  }
  toolTipBtnNewTicket() {
    if (this.isInternal) {
      return 'Cliquer pour saisir votre problème ou demande';
    }
    return 'Vous serez redirigé vers GLPI';
  }
  openNewTicket() {
    if (this.isInternal) {
      this.router.navigate([this.link]);
    } else {
      window.open(this.link, '_blank');
    }
  }
  private _loadParameter() {
    this.subSink.sink = this.paramService
      .getByName(parameters.newTicketUrl)
      .subscribe((val) => {
        this.link = val[0].value;
        console.log(this.link);
        this.isInternal = this.link.startsWith('/');
      });
  }
  private _getTickets(page = 1, search = this.searchTick) {
    this.pubSubSvc.publish('loading', true);
    this.page = page;
    this.subSink.sink = this.ticketService.myTickets(this.MAX_ITEMS, (page - 1) * this.MAX_ITEMS, search)
      .pipe(finalize(() => this.pubSubSvc.publish('loading', false)))
      .subscribe(tickets => {
        this.total = tickets.total;
        this.totalView = tickets.totalView;
        this.dataSource.load(tickets.list);
        this.tickets = tickets.list;
    });
  }
  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}


