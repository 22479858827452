<mat-card>
  <mat-card-title><p8-layout-settings-header [titlePath]="['Mes demandes']" [iconRacine]="'view_list'"></p8-layout-settings-header></mat-card-title>

  <mat-card-content>
    <div class="example-container mat-elevation-z0">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="95" fxFlex.xs="90" fxFlex.sm="80" fxFlexOrder="1" fxFlexOrder.xs="2">
          <mat-form-field>
            <input matInput name="searchText"
                   id="SearchBox"
                   matTooltip="N° ticket, objet, description, ..."
                   type="text" class="form-control"
                   placeholder="Chercher ..."
                   [formControl]="term">
            <mat-icon matSuffix color="accent">search</mat-icon>
          </mat-form-field>
        </div>

        <span class="fill-space"></span>

        <div style="padding-top: 5px" fxFlexOrder="2" fxFlexOrder.xs="1">
          <button [ngStyle.xs]="{width: '100%'}"
                  color="accent" mat-raised-button
                  (click)="openNewTicket()"
                  [matTooltip]="toolTipBtnNewTicket()">
            <mat-icon>add</mat-icon> NOUVELLE DEMANDE
          </button>
        </div>
      </div>
      <div>
        <mat-table #table [dataSource]="dataSource" matSort matSortDisableClear>
          <!-- ID Column -->
          <ng-container cdkColumnDef="id">
            <mat-header-cell *cdkHeaderCellDef> N°</mat-header-cell>
            <mat-cell *cdkCellDef="let row"><span class="text-for-datatable">{{row.id}}</span></mat-cell>
          </ng-container>

          <!-- Title Column -->
          <ng-container cdkColumnDef="title">
            <mat-header-cell *cdkHeaderCellDef [fxFlex]="60"> Objet</mat-header-cell>
            <mat-cell *cdkCellDef="let row" [fxFlex]="60">
              <span class="text-for-datatable p8-cm_data-table__cell-truncate" matTooltip="{{row.title}}">{{row.title}}</span>
            </mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container cdkColumnDef="status">
            <mat-header-cell *cdkHeaderCellDef> Statut</mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <span class="text-for-datatable">{{row.status_object.label}}
              </span>
            </mat-cell>
          </ng-container>

          <!-- Date -->
          <ng-container cdkColumnDef="modified">
            <mat-header-cell *cdkHeaderCellDef><span>Date</span></mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <span class="text-for-datatable">{{row.modified | date:'dd/MM/yyyy'}}
              </span>
            </mat-cell>
          </ng-container>

          <!-- Button Column -->
          <ng-container cdkColumnDef="link" >
            <mat-header-cell *cdkHeaderCellDef>Lien</mat-header-cell>
            <mat-cell *cdkCellDef="let row">
              <button mat-icon-button
                      matTooltip="Voir"
                      (click)="goToTicket(row.id)" data-cy="btn-overview-ticket-view">
                <mat-icon color="accent">visibility
                </mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: displayedColumns;"
                   [ngClass]="{'yellow': row.individu.uid === this.user.username}"
                   (click)="goToTicket(row.id)"
                   class="datatable-row"
                   [style.background-color]="isSelected(row.id)"></mat-row>
        </mat-table>
      </div>
    </div>
    <div fxLayoutAlign="end" fxLayoutAlign.xs="start">
      <app-pagination fxFlex.xs="50" [page]="page" [maxItems]="MAX_ITEMS" [total]="total" [totalView]="totalView"
                      (paginationChangedPage)="changePage($event)"
                      [displayCountPages]="true"
                      [colorButton]="'accent'">
      </app-pagination>
    </div>
    <div *ngIf="total > 0" fxLayoutAlign="start" fxLayoutAlign.xs="start">
      <span id="legende-yellow"></span><h6>&nbsp;&nbsp;vos tickets&nbsp;</h6>
      <span id="legende-white"></span><h6>&nbsp;&nbsp;tickets dans lesquels vous êtes en copie</h6>
    </div>
  </mat-card-content>
</mat-card>
